import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

class PostList extends Component {
  toCapitalize(authorName) {
    let splitStr = authorName.toLowerCase().split("-")
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }
  render() {
    const { post } = this.props
    let postedDate = new Date(`${post.node.frontmatter.date}`)
    let postedFixedDate = ` ${postedDate.getDate()}-${postedDate.getMonth() +
      1}-${postedDate.getFullYear()}`

    return (
      <div className="post-item">
        <div className="thumbnail-container">
          <Link to={post.node.frontmatter.path}>
            <Img
              fixed={post.node.frontmatter.thumbnail.childImageSharp.fixed}
            />
          </Link>
        </div>
        <div className="content">
          <p className="category-info">
            <Link
              to={`/category/${post.node.frontmatter.categories[0].toLowerCase()}`}
            >
              {this.toCapitalize(post.node.frontmatter.categories[0])}
            </Link>
          </p>

          <h2 className="post-title">
            <Link to={post.node.frontmatter.path}>
              {post.node.frontmatter.title}
            </Link>
          </h2>
          <p className="post-detail">
            {post.node.excerpt}

            <Link to={post.node.frontmatter.path}>Read More</Link>
          </p>

          <p className="post-author">
            By{" "}
            <Link to={`/author/${post.node.frontmatter.author.toLowerCase()}`}>
              {this.toCapitalize(post.node.frontmatter.author)}
            </Link>
            , On {postedFixedDate}
          </p>
        </div>
      </div>
    )
  }
}

export default PostList
